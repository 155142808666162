import React from 'react'
import { shape, string } from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'

import LocalizationContext, { LOCALES } from '../../../components/LocalizationContext'
import MetaData from '../../../components/common/MetaData'
import ProductGrid from '../../../components/shop/ProductGrid'
import ShopLayout from '../../../components/shop/ShopLayout'
import MobileCartBlock from '../../../components/shop/MobileCartBlock'

import styles from '../../../styles/books.module.css'

const BooksPage = ({ location }) => {
    const data = useStaticQuery(graphql`
        {
            file(relativePath: { eq: "douces-soupes/00-couverture.png" }) {
                childImageSharp {
                    fixed(width: 180) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    return (
        <LocalizationContext.Provider value={{ locale: LOCALES.EN, localizedURL: `/fr/livres/` }}>
            <MetaData location={location} />
            <ShopLayout pathname={location.pathname}>
                <h1 className={styles.title}>My books (paperback & ebook)</h1>
                <p>In addition to writting blog posts and doing freelance food stylism and photography, I write cookbooks which you can find on this page.</p>
                <MobileCartBlock />
                <ProductGrid products={[
                    {
                        name: `Douces Soupes`,
                        caption: `A beautiful book with 25 soup recipes for Fall and Winter.`,
                        path: `/fr/livres/douces-soupes/`,
                        image: data.file,
                    },
                ]} />
            </ShopLayout>
        </LocalizationContext.Provider>
    )
}

BooksPage.propTypes = {
    location: shape({
        pathname: string.isRequired,
        search: string.isRequired,
    }).isRequired,
}

export default BooksPage
